<template>
    <h1 style="opacity: 1; color: black;">{{ headerText }}</h1>
    <div v-if="editingExisting">
        <h3>Currently Editing:</h3>
        <h5>{{ oldEsuVals.esuName }}</h5>
    </div>
    <div v-if="message['existsMessage']">
        <h4><span>{{ message['existsMessage'] }}</span></h4>
    </div>

    <form @submit.prevent="submitForm">
        <div class="row">
            <div class="col-2"></div>
            <div class="col-4">
                <div class="col">
                    <label>CDN<span>*</span></label>
                    <input class="form-control" v-model="esu.esuCdn" id="esuCdn" placeholder="CDN" :disabled="editingExisting" required/>
                </div>
            </div>
            <div class="col-4">
                <label>Primary ESU Address<span>*</span></label>
                <input class="form-control" type="text" v-model="esu.primaryEsuAddress" id="primaryEsuAddress" placeholder="Primary Address"
                       :disabled="!showFullForm"
                       required/>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-4">
                <label>ESU Name<span>*</span></label>
                <input class="form-control" type="text" v-model="esu.esuName" id="esuName" placeholder="ESU Name" required
                       :disabled="!showFullForm"/>
            </div>
            <div class="col-4">
                <label>Secondary ESU Address</label>
                <input class="form-control" type="text" v-model="esu.secondaryEsuAddress" id="secondaryEsuAddress"
                       placeholder="Secondary Address (ex. PO Box)" :disabled="!showFullForm"/>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-4">
                <label>Primary Phone Number<span>*</span></label>
                <input class="form-control" v-model="esu.esuPhoneNumber" id="esuPhoneNumber" placeholder="Phone Number" required
                       :disabled="!showFullForm">
            </div>
            <div class="col-4">
                <label>City<span>*</span></label>
                <input class="form-control" v-model="esu.esuCity" id="esuCity" placeholder="City" :disabled="!showFullForm" required/>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-4">
                <label>Fax Number</label>
                <input class="form-control" v-model="esu.esuFaxNumber" id="esuFaxNumber" placeholder="Fax Number" :disabled="!showFullForm">
            </div>
            <div class="col-4">
                <label>Zip Code<span>*</span></label>
                <input class="form-control" type="text" v-model="esu.esuZipCode" id="districtZipCode" placeholder="Zip Code" required
                       :disabled="!showFullForm"/>
            </div>
            <div class="col-2"></div>
        </div>
        <button class="button btn-primary" type="submit" :disabled="!canSubmit">{{ submitText }}</button>
    </form>
</template>

<script>
import {useStore} from "vuex";

import {onBeforeMount, ref, computed, watch} from "vue";
import axios from "axios";
import {API_URL, ZIP_CODE_REGEX} from "../../../../../Constants";
import {createPhoneInputMask, numericStringMask} from "@/InputMaskFunctions";
import Swal from "sweetalert2";
import {isEqual} from "lodash/lang";

export default {
	name: "EsuForm",
	props: {
		idEsu: Number,
		editingExisting: Boolean,
		headerText: String,
		submitText: String
	},
	setup(props, context) {
		const store = useStore();
		const authToken = computed(() => store.getters['auth/authenticated']);
		let esu = ref({});
		let oldEsuVals = ref({});
		let exists = ref(true);
		let message = ref({});

		onBeforeMount(() => {
			if (props.idEsu !== null && props.idEsu !== undefined) {
				getEsu();
			}
		})

		watch(() => esu.value.esuZipCode, () => {
			if (!ZIP_CODE_REGEX.test(esu.value.esuZipCode)) {
				esu.value.esuZipCode = esu.value.esuZipCode.substring(0, esu.value.esuZipCode.length - 1);
			}
			if (esu.value.esuZipCode.length > 5) {
				esu.value.esuZipCode = esu.value.esuZipCode.substring(0, esu.value.esuZipCode.length - 1);
			}
		})

		watch(() => esu.value.esuPhoneNumber, () => {
			const numeric = esu.value.esuPhoneNumber.replaceAll(/\D/g, '');
			let mask = createPhoneInputMask(numeric);
			esu.value.esuPhoneNumber = numericStringMask(numeric, mask);
		})

		watch(() => esu.value.esuFaxNumber, () => {
			const numeric = esu.value.esuFaxNumber.replaceAll(/\D/g, '');
			let mask = createPhoneInputMask(numeric);
			esu.value.esuFaxNumber = numericStringMask(numeric, mask);
		})

		const getEsu = () => {
			let get_uri = API_URL + "/esu/displayById?_idEsu=" + parseInt(props.idEsu);
			axios.get(get_uri)
				.then((result) => {
					esu.value = result.data;
					if (esu.value.esuFaxNumber === null || esu.value.esuFaxNumber === undefined ||
						esu.value.esuFaxNumber === "") {
						esu.value.esuFaxNumber = "";
					}

					oldEsuVals.value = {...esu.value};
				})
		}

		const showFullForm = computed(() => {
			let valid_cdn_length = false;

			if (esu.value.esuCdn) {
				valid_cdn_length = esu.value.esuCdn.length === 11;
				// check exits
			}

			return valid_cdn_length && (!exists.value || props.editingExisting);

		})

		const canSubmit = computed(() => {
			let valid_form;

			if (props.editingExisting) {
				valid_form = !isEqual(oldEsuVals.value, esu.value);
			}

			return valid_form;
		})

		function submitForm() {
			let post_body = {
				idEsu: props.idEsu,
				esuName: esu.value.esuName,
				primaryEsuAddress: esu.value.primaryEsuAddress,
				secondaryEsuAddress: esu.value.secondaryEsuAddress,
				esuPhoneNumber: esu.value.esuPhoneNumber,
				esuFaxNumber: esu.value.esuFaxNumber,
				esuCdn: esu.value.esuCdn,
				esuCity: esu.value.esuCity,
				esuZipCode: esu.value.esuZipCode
			}

			if (props.editingExisting) {
				submitEditEsu(post_body);
			}
		}

		async function submitEditEsu(postBody) {
			let post_uri = API_URL + "/esu/update";
			await axios.post(post_uri, postBody, {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				Swal.fire({
					title: "Updated ESU",
					text: "Successfully Updated " + esu.value.esuName,
					icon: 'success'
				}).then(function () {
					location.reload();
				})
			})
		}

		return {
			esu,
			message,
			showFullForm,
			oldEsuVals,
			canSubmit,
			submitForm
		}
	}
}
</script>

<style scoped>
span {
    color: red;
}

input {
    height: 41px;
}
</style>