<template>
  <div class="row">
    <esu-form :submit-text="'Submit Changes'" :id-esu="idEsu" :editing-existing="true"
              :header-text="'Edit a Nebraska ESU'"/>
  </div>
</template>

<script>
import EsuForm from "@/views/SuperUserViews/EducationalOrganizationManagement/ESU/EsuForm";

export default {
  name: "EsuEdit",
  components: {EsuForm},
  props: {
    idEsu: Number
  },
  setup(props, context) {

  }
}
</script>

<style scoped>

</style>